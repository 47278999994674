$step-selector-color: #cdcdcd;

.tabs.tabs-stepper{
  display: none;
  margin-bottom: 40px;

  @include breakpoint(mediumlarge){
    display: block;
  }

  .tabs-stepper-step{
    position: relative;
    z-index: 0;
    float: left;
    padding: 0 30px;

    &::before,
    &::after{
      content: "";
      position: absolute;
      top: 30px;
      display: block;
      height: 2px;
      width: 50%;
      background: $step-selector-color;
    }

    &::before{
      left: 0;
    }

    &::after{
      right: 0;
    }

    &:first-child{
      padding-left: 0;

      &::before{
        display: none;
      }
    }

    &:last-child{
      padding-right: 0;

      &::after{
        display: none;
      }
    }

    &.is-active .step-selector{
      .step-selector-number{
        background: $secondary;
        color: $primary;
      }
    }

    > a{
      padding: 0;
      color: $body-font-color;
      font-weight: normal;

      &:hover,
      &:focus{
        text-decoration: none;
      }
    }

    a:hover,
    a:focus,
    a[aria-selected='true']{
      background: transparent;
      color: $body-font-color;
    }
  }

  .step-selector{
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    font-size: 1.3rem;
    line-height: 1.2;

    .step-selector-number{
      padding: 18px;
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
      border-radius: 50%;
      background: $step-selector-color;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 800;
      line-height: 1;
    }

    .step-selector-text{
      text-align: center;
    }
  }
}
