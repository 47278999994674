// Customized for the HDS cookie consent script

.disabled-iframe{
  background-color: $hel-grayscale-5;
  cursor: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &.ql-video{
    display: flex;
  }
}

.dataconsent-warning{
  flex-direction: column;

  .dataconsent-message{
    font-size: 1rem;
    margin: 1rem;
    padding: 1rem;
    background-color: $white;

    @include breakpoint(medium){
      margin: 3rem;
      padding: 2rem;
    }

    .icon{
      width: 1.5rem;
      height: 1.5rem;
    }

    .buttons{
      display: flex;
      flex-direction: column;

      @include breakpoint(medium){
        flex-direction: row;
      }

      .button{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        max-width: 400px;
        padding: 1.125rem 1.5rem;

        &:last-child{
          margin-left: 0;
        }
      }
    }
  }
}
