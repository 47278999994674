.card-attachment{
  .card-image{
    position: relative;
    padding-bottom: 50%;
    overflow: hidden;
    background-position: center center;
    background-size: cover;

    img{
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
