.process-phase-indicator{
  display: flex;
  flex-direction: column;

  @include breakpoint(medium){
    flex-direction: row;
  }

  > *{
    @include breakpoint(medium){
      margin-left: 25px;
    }

    &:first-child{
      @include breakpoint(medium){
        margin-left: 0;
      }
    }
  }

  .process-phase-step,
  .process-phase-name{
    font-weight: $global-weight-bold;
  }
}

.process-section{
  padding: 3rem 0;

  .process-section-cta{
    margin-top: 2rem;
  }

  .process-section__title{
    margin-bottom: 1.2rem;
  }
}
