#most-commented{
  a{
    font-weight: $global-weight-normal;
    text-decoration: none;

    &:hover,
    &:focus{
      text-decoration: underline;
    }
  }
}
