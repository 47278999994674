.authors-detailed{
  .author{
    font-size: .8rem;
  }

  .author-data{
    margin: .6rem 0;
    padding-left: 55px;

    .author__avatar{
      margin-left: -55px;
      align-self: flex-start;

      img{
        width: 40px;
        height: 40px;
        margin-top: 3px;
      }
    }
  }
}

.authors-expanded{
  margin: 1rem 0 5rem;
  padding: 2rem;

  .authors-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .author-data{
    flex: 1 0 25%;
    justify-content: center;
    max-width: 25%;
    padding: 1rem;
    word-break: break-all;

    .author__avatar{
      position: static;
      margin-left: 0;
      margin-bottom: 1rem;
    }
  }

  .author-data__main{
    margin: 0;
  }

  .author{
    flex-direction: column;
    text-align: center;
  }

  .author__avatar{
    img{
      width: 70px;
      height: 70px;
      margin-top: 0;
    }
  }
}
