@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

// $slick-font-path: "fonts/" !default;
$slick-font-family: "slick" !default;
// $slick-loader-path: "media/images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: .75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;

/* Slider */

.slick-list{
  .slick-loading &{
    background: #fff url("images/slick/ajax-loader.gif") center center no-repeat;
  }
}

/* Icons */
@if $slick-font-family == "slick"{
  @font-face{
    font-family: "slick";
    src: url("fonts/slick.eot");
    src: url("fonts/slick.eot?#iefix") format("embedded-opentype"), url("fonts/slick.woff") format("woff"), url("fonts/slick.ttf") format("truetype"), url("fonts/slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

/* Arrows */

.slick-change-prev,
.slick-change-next{
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
}

.slick-change-prev{
  left: 0;
}

.slick-change-next{
  right: 0;
}

.slick-prev,
.slick-next{
  position: absolute;
  z-index: 1;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 30px;
  cursor: pointer;
  background: $hel-engel-light;
  color: $hel-black;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  border-radius: 0;

  &:hover,
  &:focus{
    outline: none;
    background: $hel-engel;
    color: $hel-black;
  }

  &.slick-disabled::before{
    opacity: $slick-opacity-not-active;
  }
}

.slick-prev{
  letter-spacing: 4px;
  left: -25px;

  [dir="rtl"] &{
    left: auto;
    right: -25px;
  }
}

.slick-next{
  letter-spacing: -4px;
  right: -25px;

  [dir="rtl"] &{
    left: -25px;
    right: auto;
  }
}

/* Dots */

.slick-dotted.slick-slider{
  margin-bottom: 30px;
}

.slick-dots{
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;

  li{
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button{
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;

      &:hover,
      &:focus{
        outline: none;

        &::before{
          opacity: $slick-opacity-on-hover;
        }
      }

      &::before{
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        background: transparent;
        border: 2px solid $slick-dot-color;
        border-radius: 50%;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-active button::before{
      background: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}
