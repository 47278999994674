$author-data-color: $muted;

.author-data{
  color: $author-data-color;

  .author__name{
    color: $anchor-color;
  }

  a{
    font-weight: $global-weight-normal;
    text-decoration: none;

    &:hover,
    &:focus{
      text-decoration: underline;
    }
  }
}
