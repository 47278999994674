// Import the Helsinki variables because this is also loaded for the admin panel
// as the decidim-core gem's utils/settings.scss gets a priority for the admin
// panel as well.
@import "stylesheets/helsinki-variables";

// Foundation Variables
$primary-color: null;
$secondary-color: null;
$success-color: null;
$warning-color: null;
$alert-color: null;
$-zf-size: null;
$-zf-bp-value: null;

// Variables

$primary: $hel-black !default;
$primary-rgb: $hel-black-rgb !default;
$secondary: $hel-engel !default;
$secondary-rgb: $hel-engel-rgb !default;
$success: $hel-success !default;
$success-rgb: $hel-success-rgb !default;
$warning: $hel-alert !default;
$warning-rgb: $hel-alert-rgb !default;
$alert: $hel-error !default;
$alert-rgb: $hel-error-rgb !default;
$highlight: $hel-info !default;
$highlight-rgb: $hel-info-rgb !default;
$highlight-alternative: $hel-copper !default;
$highlight-alternative-rgb: $hel-copper-rgb !default;

$proposals: #238ff7 !default;
$actions: #57d685 !default;
$debates: #fa6c96 !default;
$meetings: #fabc6c !default;

$twitter: #55acee !default;
$facebook: #3b5998 !default;
$google: #4285f4 !default;

// background colors used in public diff views
$color-addition: #e6ffed !default;
$color-removal: #ffeef0 !default;

// Custom variables for easier access
$bg-primary: $hel-engel;
$bg-secondary: $hel-engel-light;
$footer-color: $bg-secondary;

// Paahtimo
$paahtimo-secondary: $hel-fog;
$paahtimo-bg-primary: $hel-fog-light;
$paahtimo-bg-secondary: $hel-fog-light;
$paahtimo-footer-color: $hel-fog-light;
