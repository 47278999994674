.d-flex{ display: flex !important; }

$margins:(0: 0, 1: 10px, 2: 20px, 3: 30px, 4: 40px, 5: 50px, auto: auto);

@each $name, $margin in $margins{
  .mt-#{$name}{ margin-top: #{$margin} !important; }
  .mb-#{$name}{ margin-bottom: #{$margin} !important; }
  .mr-#{$name}{ margin-right: #{$margin} !important; }
  .ml-#{$name}{ margin-left: #{$margin} !important; }

  .mx-#{$name}{
    margin-right: #{$margin} !important;
    margin-left: #{$margin} !important;
  }

  .my-#{$name}{
    margin-top: #{$margin} !important;
    margin-bottom: #{$margin} !important;
  }
}

$paddings:(0: 0, 1: 10px, 2: 20px, 3: 30px, 4: 40px, 5: 50px);

@each $name, $padding in $paddings{
  .pt-#{$name}{ padding-top: #{$padding} !important; }
  .pb-#{$name}{ padding-bottom: #{$padding} !important; }
  .pr-#{$name}{ padding-right: #{$padding} !important; }
  .pl-#{$name}{ padding-left: #{$padding} !important; }

  .px-#{$name}{
    padding-right: #{$padding} !important;
    padding-left: #{$padding} !important;
  }

  .py-#{$name}{
    padding-top: #{$padding} !important;
    padding-bottom: #{$padding} !important;
  }
}

.flex-row{ flex-direction: row !important; }
.flex-column{ flex-direction: column !important; }
.flex-row-reverse{ flex-direction: row-reverse !important; }
.flex-column-reverse{ flex-direction: column-reverse !important; }

.flex-wrap{ flex-wrap: wrap !important; }
.flex-nowrap{ flex-wrap: nowrap !important; }
.flex-wrap-reverse{ flex-wrap: wrap-reverse !important; }
.flex-fill{ flex: 1 1 auto !important; }
.flex-grow-0{ flex-grow: 0 !important; }
.flex-grow-1{ flex-grow: 1 !important; }
.flex-shrink-0{ flex-shrink: 0 !important; }
.flex-shrink-1{ flex-shrink: 1 !important; }

.justify-content-start{ justify-content: flex-start !important; }
.justify-content-end{ justify-content: flex-end !important; }
.justify-content-center{ justify-content: center !important; }
.justify-content-between{ justify-content: space-between !important; }
.justify-content-around{ justify-content: space-around !important; }

.align-items-start{ align-items: flex-start !important; }
.align-items-end{ align-items: flex-end !important; }
.align-items-center{ align-items: center !important; }
.align-items-baseline{ align-items: baseline !important; }
.align-items-stretch{ align-items: stretch !important; }

.align-content-start{ align-content: flex-start !important; }
.align-content-end{ align-content: flex-end !important; }
.align-content-center{ align-content: center !important; }
.align-content-between{ align-content: space-between !important; }
.align-content-around{ align-content: space-around !important; }
.align-content-stretch{ align-content: stretch !important; }

.align-self-auto{ align-self: auto !important; }
.align-self-start{ align-self: flex-start !important; }
.align-self-end{ align-self: flex-end !important; }
.align-self-center{ align-self: center !important; }
.align-self-baseline{ align-self: baseline !important; }
.align-self-stretch{ align-self: stretch !important; }
