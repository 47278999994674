.side-panel{
  background-color: $body-background;

  &.subpage{
    border-right: 0;
  }
}

.side-panel__tabs{
  border: none;

  .tabs-title{
    border-bottom: $border;

    &:last-of-type{
      @include breakpoint(medium){
        margin-bottom: 4rem;
      }
    }

    > [role=tab],
    > a{
      color: $body-font-color;

      &:hover{
        color: $anchor-color;
      }
    }

    &.is-active > [role=tab],
    &.is-active > a{
      color: $anchor-color;
      box-shadow: inset 4px 0 0 var(--primary);
    }
  }
}
